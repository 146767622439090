// this is from example project - https://github.com/lafeber/fullcalendar-hotwire
import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

export default class extends Controller {
    static values = {
        url: String,
        initialView: String,
        headerToolbar: Object
    }
    static targets = [ "popup", "window" ];

    connect() {
        this.calendar = new Calendar(this.windowTarget, {
            plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin ],
            selectable: true,
            timeZone: 'local',
            events: this.urlValue,
            initialView: this.initialViewValue || 'dayGridMonth',
            headerToolbar: this.headerToolbarValue || {
                left: 'prev,next',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,listWeek'
            },
            // eventClick: function(info) {
            //     overlay.src = '/events/' + info.event.id + '/edit';
            // },
            // select: function(info) {
            //     overlay.src = '/events/new?start=' + info.startStr + '&end=' + info.endStr;
            // },
        });

        // window.addEventListener('load', () => {
            this.calendar.render();
        // });
    }

    refresh(e) {
        if (e.detail.success) {
            this.calendar.refetchEvents();
        }
    }
}

import {Controller} from "@hotwired/stimulus"
import {get} from "@rails/request.js"

export default class extends Controller {
    static values = {
        start: String,
        end: String,
        id: String,
        citem: String
    }

    static targets = ['select', 'x', 'y']

    connect() {

    }

    search(e) {
        if (this.xTarget.value < this.yTarget.value) {
            let params = new URLSearchParams()
            params.append("start_date", this.xTarget.value)
            params.append("end_date", this.yTarget.value)
            params.append("target", this.selectTarget.id)
            params.append("citem", this.citemValue)
            if (this.idValue != null) {
                params.append("reservation_id", this.idValue)
            }
            get(`/items/select_list?${params}`, {
                responseKind: "turbo-stream"
            })
        }
    }

    changeStartDate() {

    }

    changeEndDate(e) {

    }
}
